<template>
    <v-form v-if="user" @submit.prevent="submitUserForm(userForm.user_id, userForm)">
        <div class="space-y-8">
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                    <v-form-label>Name</v-form-label>
                    <v-form-input v-model="userForm.name" type="text" name="name" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                    <v-form-label>Email</v-form-label>
                    <v-form-input v-model="userForm.email" type="email" name="email" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []" class="cols-span-1">
                    <v-form-label>Discord User ID</v-form-label>
                    <v-form-input v-model="userForm.discord_user_id" type="text" name="discord_user_id" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []" class="cols-span-1">
                    <v-form-label>Discord Username</v-form-label>
                    <v-form-input v-model="userForm.discord_username" type="text" name="discord_username" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>

                <div class="col-span-full grid grid-cols-1 md:grid-cols-2 gap-8">
                    <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                        <v-form-label>Address Line 1</v-form-label>
                        <v-form-input v-model="userForm.address_line_1" type="text" name="address_line_1" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                    </v-form-group>

                    <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                        <v-form-label>Address Line 2</v-form-label>
                        <v-form-input v-model="userForm.address_line_2" name="address_line_2" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                    </v-form-group>
                </div>

                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                    <v-form-label>City</v-form-label>
                    <v-form-input v-model="userForm.city" type="text" name="city" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                    <v-form-label>State</v-form-label>
                    <v-form-input v-model="userForm.state" type="text" name="state" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                    <v-form-label>Zip Code</v-form-label>
                    <v-form-input v-model="userForm.zip_code" type="text" name="zip_code" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                    <v-form-label>Phone Number</v-form-label>
                    <v-form-input v-model="userForm.phone_number" type="text" name="phone_number" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>
            </div>

            <v-form-group class="flex w-full justify-end">
                <v-button class="w-full sm:w-auto" color="primary" text-alignment="center" :disabled="isLoading || !($me.hasPermission('users.update') || userForm.user_id == me.user_id)">
                    Save Changes
                </v-button>
            </v-form-group>
        </div>
    </v-form>
</template>

<script>
import UserService from "@/services/modules/user-service";

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userService: UserService,
            userForm: {},
            me: null,
        };
    },
    watch: {
        user: {
            handler(value) {
                this.userForm = value;
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.me = this.$me.getUser();
    },
    methods: {
        submitUserForm(userId, payload) {
            this.toggleLoading();

            this.userService.update(userId, payload)
                .then((response) => {
                    this.$emit("user-updated", response);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
    },
};
</script>
